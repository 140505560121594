/* MainPage.css */
.main-background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 0;
}

.home-header {
  text-align: center;
  margin-bottom: 20px;
}

.btn-sync {
  position: absolute;
  z-index: 1000;
  margin-top: 20px;
  top: 10px;
  right: 20px;
}
