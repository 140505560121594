body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: "Poppins", sans-serif; /* Use a modern font like Poppins */
  background-color: #f5f6fa; /* Softer background color */
}

.register-container,
.login-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

h1 {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 12px 16px;
  margin: 8px 0;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #007bff;
  outline: none;
}

button {
  padding: 12px 20px;
  margin: 12px 0;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

button:active {
  transform: translateY(0);
}

button:last-of-type {
  background-color: #ffc107;
  color: black;
}

button:last-of-type:hover {
  background-color: #e0a800;
}

.app-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  height: 50px;
}
.issue-description {
  width: 100%;
  margin: 0;
}

.appIcon {
  width: 50px;
  height: 50px;
  padding: 0;
}

.googleIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
}

.google-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  margin-top: 10px;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.google-row:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.google-row button {
  background: none;
  border: none;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  display: inline-block;
  /* border: red 1px solid; */
  width: calc(100% - 10px);
}

.google-row:hover button {
  color: #007bff;
}

p {
  margin: 10px 0;
  font-size: 14px;
  color: #666;
}

p[style*="color: red"] {
  color: #e74c3c;
}

p[style*="color: green"] {
  color: #27ae60;
}
