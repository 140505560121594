.edit-button-list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  height: 80vh;
  width: 1200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.button-table-container {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  min-height: 0;
}

.button-table-wrapper {
  width: 100%;
}

.button-table-wrapper table {
  width: 100%;
}

.edit-buttons-footer {
  background-color: white;
  padding: 0 10px;
  border-top: 1px solid #e0e0e0;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
}

.flexRowNoSpace {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.inline-controls {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
}

th {
  background-color: #f2f2f2;
  text-align: center !important;
  align-items: center;
}

td {
  text-align: center !important;
  align-items: center;
  padding: 0px !important;
}

.text-input {
  width: 120px !important;
  height: 100%;
  border: none !important;
  text-align: center;
  font-size: 12px;
  padding: 4px !important;
  margin: 0 !important;
}

.number-input {
  /* width: 20px; */
  border: none;
  text-align: center;
  font-size: 12px;
  padding: 0 10px !important;
  margin: 0 !important;
  border: none !important;
}

.edit-download-button {
  background-color: #4caf50;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  /* margin: 4px 2px; */
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 10px;
  height: 30px;
}

h5 {
  margin: 0 8px;
}

.import-export-container {
  display: flex;
  flex-direction: row;
  gap: 0px;
  width: 100%;
  justify-content: space-evenly;
}

.option-box {
  margin-top: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 47%;
  font-size: 14px;
}

.btn-file-input {
  display: none;
  height: 0px;
}

.btn-upload {
  display: inline-block;
  padding: 7px 10px;
  margin-top: 5px;
  background-color: #ffc107;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 13px;
  /* height: 30px; */
}

.btn-download-template {
  background-color: #4caf50;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  padding: 7px 8px;
}
.custom-file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  height: 28px;
}

.custom-file-upload {

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  color: #ffffff;
  background-color: #007bff;
  padding: 4px 10px;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  /* transition: background-color 0.3s ease; */
  margin: 0px !important;
  /* border: none; */
  font-weight: 400;
  height: 20px;
}

.file-name {
  margin-left: 10px;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.flex-row-not-centered {
  display: flex;
  width: 95%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 30px; */
}
.edit-button-add {
  width: 150px;
  height: 30px;
  font-size: 14px;
  padding: 0px !important;
}
.edit-button-delete {
  width: 70px;
  height: 30px;
  font-size: 14px;
  padding: 0px !important;
}
input.title-input,
input.link-input {
  width: 40% !important;
  padding: 0px !important;
}
