/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

html,
body {
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: Arial, sans-serif;
}

.admin-page {
  width: 90vw;
  height: 100vh;
  padding: 20px;
  background-color: #f9f9f9;
}

.admin-header {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.user-table-container {
  width: 90%;
  max-width: 800px;
  min-height: 200px;
  overflow: auto;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #f2f2f2;
  text-transform: uppercase;
}

.btn-delete {
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 3px;
  margin: 5px;
  width: 65px;
}

.btn-delete:hover {
  opacity: 0.8;
}

select {
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

input[type="checkbox"] {
  cursor: pointer;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: left;
}

hr {
  margin: 20px 0;
}

.manage-data-section {
  margin-top: 20px;
  width: 100%;
}
.server-color {
  background-color: #ccc;
  padding: 5px;
}
