
.folder-header input {
  flex-grow: 1;
  font-size: 1em;
  max-width: 80%;
  /* max-width: 70%; */
  /* margin: 0px; */
  height: 30px;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  text-align: center;
}


.delete-icon-button {
  background-color: #F44336;
  /* background-color: red; */
  border: none;
  cursor: pointer;
  margin: 0 10px;
  width: 27px !important;
  height: 27px !important;
  border-radius: 5px;

  /* Flexbox properties to center the icon */
  display: flex;
  align-items: center;
  justify-content: center;
}

.folder-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px; /* Match grid-item height */
  width: 30px;  /* Match grid-item width */
  border-radius: 5px;
}

.folder-frame {
  position: absolute;
  width: 730px;
  height: 550px;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  border-radius: 10px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  display: flex; 
  flex-direction: column;
  overflow: hidden; 
  z-index: 100;
}

.folder-grid {
  display: grid;
  grid-template-columns: repeat(6, 100px);
  grid-template-rows: repeat(4, 100px);
  gap: 20px;
  padding: 20px;
  width: 690px;
  height: 460px;
  margin: 0px auto 0;
  position: relative;
  background-color: white;
  opacity: 1;
  
  border-radius: 0px 0px 8px 8px;
  
}

.folder-header {
  display: flex;
  /* flex-grow: 1; */
  justify-content: center;
  align-items: center;
  background-color: #4caf50;
  color: white !important;
  border: 0px;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  position: relative;
}

.folder-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;

}

.folder-small-item {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.empty-folder-icon {
  width: 48px;
  height: 48px;
  /* opacity: 0.5; */
}
