.drop-link-area {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 48px;
  height: 44px;
  /* background-color: #f0f0f0; */

  border-radius: 4px;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease;
}
