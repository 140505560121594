.report-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Overall container */
.report-page-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
}

/* The header remains visible */
.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f4f4f4;
  margin-bottom: 10px;
}

.report-header h2 {
  margin: 0;
}

/* Table styling */
.report-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  text-align: left;
  table-layout: fixed;
}

.report-table th,
.report-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

/* Set specific column widths */
.header-date {
  width: 100px !important; /* Ensure Date column has fixed width */
}

.header-from {
  width: 150px !important; /* Ensure From column has fixed width */
}

.reply-history-table th,
.reply-history-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.reply-history-table th {
  background-color: #ccc;
}

/* Scrollable table body */
.report-table tbody {
  max-height: 300px;
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%;
}

.report-table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Reply form styling */
.reply-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reply-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.reply-form button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 150px;
}

.reply-form button:hover {
  background-color: #0056b3;
}

.delete-btn {
  background-color: #dc3545;
}

.delete-btn:hover {
  background-color: #c82333;
}

.refresh-btn,
.manage-btn,
.delete-btn {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 80px;
}

.refresh-btn:hover {
  background-color: #0056b3;
}
.report-header {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 0; /* Optional: Adds space around the header */
}
