.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  /* padding: 0 10px 0 10px !important; */
  background-color: #4caf50;
  color: white !important;
  border: 0px;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 40px;
}

.app-header h3 {
   margin-right: 15px;
  /*padding-left: 10px; */
  
}
.btn-close {
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
  background-color: transparent !important;
  border: none;
  cursor: pointer;
  width: 2.5rem;
}
.btn-close img {
  width: 35px;
  height: 35px;
}

.app-body {
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* MY CSS CODE */
.flexRow {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 10px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button {
  margin: 0 10px;
}

.app-button {
  background-color: #007bff;
  color: white !important;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  height: 30px !important;
}

.app-button:hover {
  background-color: #0056b3;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  max-width: 300px;
  /* padding: 10px; */
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.app-inputs {
  display: flex;
  font-size: 14px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 90%;
  background-color: #bebebe;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px 0;
}

h3 {
  margin: 10px;
  /* background-color: red; */
}
.noWrap {
  white-space: nowrap;
}
.red {
  color: red;
  background-color: red !important;
}

.width200 {
  width: 200px !important;
}
.width175 {
  width: 175px !important;
}
.width150 {
  width: 150px !important;
}
.width125 {
  width: 125px !important;
}
.width100 {
  width: 100px !important;
}
.width90 {
  width: 90px !important;
}
.width80 {
  width: 80px !important;
}
.width70 {
  width: 70px !important;
}
.width60 {
  width: 60px !important;
}
.width50 {
  width: 50px !important;
}
.width40 {
  width: 40px !important;
}
.width30 {
  width: 30px !important;
}
.gridXY {
  background-color: ivory !important;
}
.gridID {
  background-color: cornsilk !important;
}
.alignCenter {
  text-align: center;
}

/* input {
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
} */
.fullWidth {
  width: 90%;
}
/* Style for WebKit browsers */
::-webkit-scrollbar {
  width: 20px; /* Adjust the width here */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scrollbar thumb hover color */
}

/* Group related items visually */
.group {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}

.group .section-title {
  border-bottom: none;
  margin-top: 0;
  padding-bottom: 0;
  width: 100%;
}
.input-test {
  position: absolute;
  top: 10px;
  left: 100px;
  width: 200px;
  height: 30px;
  z-index: 1000;
}

.item-delete-button {
  position: absolute;
  top: -10px !important;
  right: -10px !important;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}