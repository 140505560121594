/* Modal overlay styling */
.report-issue-modal-overlay {
  position: fixed;
  top: 5%;
  left: 5%;
  /* width: 100%; */
  height: 100%;
  width: 90%;
  background: rgba(0, 0, 0, 0.6); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content box */
.report-issue-modal-content {
  background-color: white;
  padding: 10px;
  border-radius: 8px;

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-height: 80%;
  width: 90%;
}

.hide-view-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

/* Close button styling */
.modal-close {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  width: 100px;
}
.submit-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  width: 350px;
}

.modal-close:hover {
  background-color: #c82333;
}

/* Shared styles */
.appIcon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

textarea {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #0056b3;
}

.report-table-container {
  max-height: 400px; /* Limit the table height */
  overflow-y: auto; /* Make it scrollable */
  border: 2px solid black; /* Optional: Add a border around the table */
}

table {
  width: 98%; /* Make the table take up the full width */
  border-collapse: collapse; /* Optional: To remove the space between table cells */
}

table th,
table td {
  border: 1px solid #ddd; /* Add borders to the table cells */
  padding: 8px; /* Add padding to table cells */
  text-align: left; /* Align text to the left */
}

table th {
  background-color: #f2f2f2; /* Optional: Add background color to table header */
}

.hide-view-btn {
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
  width: 100px;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs button {
  flex: 1;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff !important;
  border: none;
  border-bottom: 3px solid transparent;
  transition: all 0.2s;
}

.tabs button.active-tab {
  border-bottom: 3px solid black;
  background-color: white;
  font-weight: bold;
}

.tab-content {
  padding: 20px;
}

.reply-btn {
  width: 200px;
  padding: 8px;
  margin-bottom: 8px;
}
