.settings-switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.effect-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%; /* Adjust the width to fill the area */
}

.effect-control {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 10px 0 10px;
  gap: 0px;
}

.effect-control {
  margin-top: 0px;
  width: 96%;
}

.effect-control .effect-item {
  display: flex;
  /* align-items: center; */
  gap: 0px;
  /* margin-bottom: 10px; */
}

/* .effect-control .effect-item label {
  flex: 1;
}

.effect-control input[type="range"] {
  flex: 2;
} */

.flexRow {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flex-row-not-centered {
  display: flex;
  gap: 10px;
}

.customImageType {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* border: 1px solid #ccc; */
  /* padding: 10px; */
  border-radius: 5px;
  /* margin-top: 10px; */
  width: 100%;
}

.upload-input-container {
  width: 90%;
}

.background-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: 350px;
  overflow-y: auto;
}

.background-images-container img {
  cursor: pointer;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border 0.3s ease;
}

.background-images-container img:hover {
  border: 2px solid #007bff;
}

.section-title {
  margin-bottom: 10px;
}

.group {
  margin-bottom: 20px;
}

button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

hr {
  border: 1px solid #ccc;
  margin: 20px 0;
}
