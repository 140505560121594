.settings-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  height: 800px;
  padding: 0 !important;
  background-color: white;
  border: 0px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
  z-index: 1001;
  overflow-y: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  cursor: grab;
}

.form-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  flex-grow: 1;
}

.tabs {
  margin-top: 5px;
}

.tabs button {
  margin-right: 10px;
  padding: 6px 8px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1em;
  background-color: #189ad3;
}

.tabs button.active {
  font-weight: bold;
  border-bottom: 2px solid #000;
}

.tab-content {
  margin-top: 10px;
  height: calc(100% - 140px); /* Adjust based on the height of tabs and buttons */
  /* overflow-y: hidden;  */
}

.setting-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexRow {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center; /* Center the flex row */
}

.customImageType {
  width: 400px !important;
  padding: 8px 30px;
  font-size: 1.2em;
}
.unsplash {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.settings-close-button,
.settings-cancel-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s;
}

.settings-close-button:hover,
.settings-cancel-button:hover {
  background-color: #e0e0e0;
}

.section-title {
  font-size: 16px;
  font-weight: bold;
  margin: 5px;
  width: 100%;
  padding: 5px 0 0 0 !important;
}

.settings-panel {
  padding: 10px;
  overflow: hidden;
  margin: 0;
  /* height: 500px; */
}

.flexRow label {
  flex: 1;
}

.flexRow input[type="range"] {
  flex: 2;
}
