.edit-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  height: 80% !important;
  z-index: 2000;
}

.form-title {
  margin: 0;
}

.item-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 80px;
}

.input-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
  padding: 5px;
}

.icon-select {
  height: 31px !important; /* Match the input height */
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 4px; /* Optional: To match the input’s look */
  box-sizing: border-box; /* Ensures padding does not affect height */
}
p {
  margin: 0 5px 0 0 !important;
}

.matching-images-container {
  /* overflow-y: auto; Allow scrolling if necessary */
  transition: height 0.2s ease-in-out; /* Smooth height adjustment */
}

.image-matches {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 4px;
  width: 100%;
  overflow-y: auto;
}

.edit-input {
  color: #333 !important;
  font-weight: 200 !important;
  border: 1px solid #ccc;
  height: 18px !important;
  padding: 5px;
  font-size: 14px;
}

.image-matches img {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  cursor: pointer;
}

.selected-icon {
  border: 0.1px solid #aaa !important;
  border-radius: 10px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.color-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.color-picker-container label {
  margin-bottom: 5px;
}

.action-buttons {
  display: flex;
  justify-content: center !important;
  width: 100% !important;
  margin: 15px;
  background-color: #f9f9f9;
}

.del-button {
  width: 150px !important;
  background-color: #dc3545 !important;
  padding: 2px 10px;
  color: white !important;
  font-size: 16px;
  box-sizing: border-box;
  height: 28px;
}

.save-button {
  width: 150px;
  padding: 2px 10px;
  color: white;
  font-size: 16px;
  background-color: #28a745 !important;
  box-sizing: border-box;
  height: 28px;
}
