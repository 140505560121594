.edit-widgets-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  height: 80vh;
  width: 1200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.edit-widgets-modal .app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.edit-widgets-modal .btn-close {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}
