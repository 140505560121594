.add-item-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  width: 80%;
  height: 80%;
  z-index: 2000;
}

.form-title {
  margin: 0;
}


.form-group-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  width: 95%;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 10px;
  margin: 10px;
  border: 1px solid red;
  width: 100% !important;
}

.image-matches img {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  cursor: pointer;
}

.selected-icon {
  border: 3px solid #007bff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.icon-select {
  height: 31px !important; /* Match the input height */
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 4px; /* Optional: To match the input’s look */
  box-sizing: border-box; /* Ensures padding does not affect height */
  color: #333;
  font-family: "Roboto", sans-serif;
}

.color-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.color-picker-container label {
  margin-bottom: 5px;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: auto;
  background-color: #f9f9f9;
}

.action-buttons-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10PX;
  /* background-color: RED; */
}


.app-button {
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
}

.upload-image {
  width: 95%;
  margin-left: 10px;
}

.image-list-picker {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}
.save-button {
  width: 200px;
  padding: 2px 10px;
  color: white !important;
  font-size: 16px;
}
