.about-you {
  position: absolute;
  top: 100px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
  background-color: white;
  padding: 0 10px;
  border-radius: 15px;
}
