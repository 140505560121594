.widget-table-container {
  padding: 20px;
}

.widget-table-flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.widget-table-wrapper {
  overflow-x: auto;
}

.widget-table {
  width: 100%;
  border-collapse: collapse;
}

.widget-table th,
.widget-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.widget-table-cell-id {
  white-space: nowrap; /* Prevent the text from wrapping to the next line */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Show ellipsis (...) for the hidden text */
  max-width: 50px;
}

/* .widget-table-cell-title {
  width: 200px; 
} */

.widget-table-cell-gridX,
.widget-table-cell-gridY,
.widget-table-cell-main_id,
.widget-table-cell-folder_id,
.widget-table-cell-common_id,
.widget-table-cell-visible,
.widget-table-cell-state {
  width: 80px; /* Adjust width to fit inputs */
}

.widget-table-input-id,
.widget-table-input-title,
.widget-table-input-gridX,
.widget-table-input-gridY,
.widget-table-input-main_id,
.widget-table-input-folder_id,
.widget-table-input-common_id,
.widget-table-input-visible,
.widget-table-input-state {
  width: 100%;
  box-sizing: border-box; /* Ensures padding and border are included in total width */
  padding: 5px;
}

.widget-table-cell-actions {
  white-space: nowrap;
}

.widget-table-btn-save,
.widget-table-btn-edit,
.widget-table-btn-update,
.widget-table-btn-add,
.widget-table-delete-button {
  margin-right: 2px;
  width: 70px; /* Set consistent button width */
  white-space: nowrap; /* Prevent text wrapping */
  padding: 5px;
  text-align: center;
  box-sizing: border-box;
}

.widget-table-addButton {
  background-color: #ffc107;
  color: #000;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 2px;
  width: 200px; /* Fixed width for "Add New Widget" button */
}
