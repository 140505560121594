.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.popup-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.popup-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.popup-buttons button:first-child {
  background-color: #4CAF50;
  color: white;
}

.popup-buttons button:nth-child(2) {
  background-color: #2196F3;
  color: white;
}

.popup-buttons button:last-child {
  background-color: #f44336;
  color: white;
}

.popup-buttons button:hover {
  opacity: 0.9;
}
