.sync-button {
  all: unset;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 6px 12px;
  font-size: 14px;
  white-space: nowrap;
}

.sync-button svg {
  margin-right: 8px;
  width: 14px;
  font-size: 14px;
  flex-shrink: 0;
  color: #555;
}

.sync-status {
  position: absolute;
  top: 0;
  left: 100px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: red;
}

.sync-button.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.sync-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.sync-button,
.upgrade-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.sync-button {
  background-color: #4CAF50;
  color: white;
}

.sync-button:hover:not(:disabled) {
  background-color: #45a049;
}

.sync-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.sync-button.syncing {
  background-color: #2196F3;
}

.upgrade-button {
  background-color: #ff9800;
  color: white;
}

.upgrade-button:hover {
  background-color: #f57c00;
}

.fa-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fa-crown {
  color: #ffd700;
}

.sync-button-group {
  display: flex;
  gap: 10px;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.menu-item:hover:not(.premium-locked) {
  background-color: #e0e0e0;
  transform: translateY(-1px);
}

.menu-item.premium-locked {
  opacity: 0.8;
  cursor: pointer;
  background: linear-gradient(135deg, #f0f0f0, #e6e6e6);
  border: 1px solid #e0e0e0;
}

.menu-item.premium-locked:hover {
  background: linear-gradient(135deg, #e6e6e6, #f0f0f0);
  opacity: 0.9;
}

.premium-icon {
  color: #ffd700;
  margin-left: 4px;
  font-size: 0.8em;
}

.upgrade-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  color: white;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 4px;
  width: 100%;
  justify-content: center;
}

.upgrade-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  background: linear-gradient(135deg, #ffc600, #ff9500);
}

.upgrade-button .fa-crown {
  font-size: 0.9em;
}
