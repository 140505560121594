/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .screen-size-display {
    font-size: 16px;
    /* width: 150px; */
  }

  .navbutton {
    font-weight: 500; /* RESPONSIVE */
    border-radius: 10px; /* RESPONSIVE */
    font-size: 1em; /* RESPONSIVE */
  }

  .main-grid-container {
    grid-template-columns: repeat(5, 10%); /* RESPONSIVE */
    grid-template-rows: repeat(9, 10%); /* RESPONSIVE */
    gap: 25%; /* RESPONSIVE */
    row-gap: 5%; /* RESPONSIVE */
    left: 35%; /* RESPONSIVE */
  }

  .common-grid-frame {
    bottom: 20px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
    width: 70%; /* RESPONSIVE */
    /* border: orange 6px solid; */
  }
  .common-grid-container {
    grid-template-columns: repeat(4, 10%); /* RESPONSIVE */
    grid-template-rows: repeat(1, 5%); /* RESPONSIVE */
    gap: 25%; /* RESPONSIVE */
    row-gap: 45%; /* RESPONSIVE */
    padding: 10px 15px 0px 10px; /* RESPONSIVE */
    height: 160px; /* RESPONSIVE */
  }

  .grid-item {
    height: 60px; /* RESPONSIVE */
    width: 60px; /* RESPONSIVE */
    border-radius: 5px; /* RESPONSIVE */
  }

  .button-item {
    height: 60px; /* RESPONSIVE */
    width: 60px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
  }
  .button-image {
    top: 8%; /* RESPONSIVE */
    left: 8%;
    width: 50px !important; /* RESPONSIVE */
    height: 50px !important; /* RESPONSIVE */
    border-radius: 12px; /* RESPONSIVE */
  }
  .button-title {
    position: absolute;
    top: 60px; /* RESPONSIVE */
    font-size: 0.8em !important; /* RESPONSIVE */
  }
  .widget-item-title {
    font-size: 0.8em !important; /* RESPONSIVE */
  }
  .folder-item {
    height: 60px; /* RESPONSIVE */
    width: 60px; /* RESPONSIVE */
  }

  /* ========== WIDGET CSS ==============*/
  .widget-item-on-grid {
    margin-top: 10px; /* RESPONSIVE */
    border-radius: 15%; /* RESPONSIVE */
    width: 60px !important; /* RESPONSIVE */
    height: 60px !important; /* RESPONSIVE */
  }

  /* =========== FOLDER STUFF ============  */
  .folder-item-container {
    border-radius: 10px; /* RESPONSIVE */
    height: 60px; /* RESPONSIVE */
    width: 60px; /* RESPONSIVE */
  }

  .folder-item-content {
    width: 30px; /* RESPONSIVE */
    height: 30px; /* RESPONSIVE */
  }

  .folder-small-item {
    width: 12px; /* RESPONSIVE */
    height: 12px; /* RESPONSIVE */
    border-radius: 2px; /* RESPONSIVE */
  }

  .empty-folder-icon {
    width: 30px; /* RESPONSIVE */
    height: 30px; /* RESPONSIVE */
  }

  .folder-item-title {
    font-size: 0.8em; /* RESPONSIVE */
  }

  .folder-item-container.empty .folder-item-title {
    margin-top: 35px; /* Space for empty folder */
  }

  .folder-item-container.not-empty .folder-item-title {
    margin-top: 30px; /* Less space for non-empty folder */
  }

  /* WEATHER DAILY ICON CSS */
  .weather-city {
    font-size: 0.8em;
    margin-bottom: 0px !important;
    padding: 0px !important;
  }

  .weather-daily-icon {
    width: 30px !important;
    height: 30px !important;
  }
  .weather-daily-temp {
    bottom: 8px;
    font-weight: 800;
    margin-top: 8px !important;
    font-size: 1em !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .screen-size-display {
    font-size: 16px;
    /* width: 150px; */
  }

  .navbutton {
    font-weight: 500; /* RESPONSIVE */
    border-radius: 10px; /* RESPONSIVE */
    font-size: 1em; /* RESPONSIVE */
  }

  .main-grid-container {
    grid-template-columns: repeat(5, 10%); /* RESPONSIVE */
    grid-template-rows: repeat(9, 10%); /* RESPONSIVE */
    gap: 25%; /* RESPONSIVE */
    row-gap: 5%; /* RESPONSIVE */
    left: 35%; /* RESPONSIVE */
  }

  .common-grid-frame {
    bottom: 20px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
    width: 70%; /* RESPONSIVE */
    /* border: orange 6px solid; */
  }
  .common-grid-container {
    grid-template-columns: repeat(4, 10%); /* RESPONSIVE */
    grid-template-rows: repeat(1, 5%); /* RESPONSIVE */
    gap: 25%; /* RESPONSIVE */
    row-gap: 45%; /* RESPONSIVE */
    padding: 10px 15px 0px 10px; /* RESPONSIVE */
    height: 220px; /* RESPONSIVE */
  }

  .grid-item {
    height: 80px; /* RESPONSIVE */
    width: 80px; /* RESPONSIVE */
    border-radius: 10px; /* RESPONSIVE */
  }

  .button-item {
    height: 80px; /* RESPONSIVE */
    width: 80px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
  }
  .button-image {
    top: 7%; /* RESPONSIVE */
    left: 7%;
    width: 70px !important; /* RESPONSIVE */
    height: 70px !important; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
  }
  .button-title {
    top: 80px; /* RESPONSIVE */
    font-size: 1em !important; /* RESPONSIVE */
  }

  .folder-item {
    height: 80px; /* RESPONSIVE */
    width: 80px; /* RESPONSIVE */
  }

  /* ========== WIDGET CSS ==============*/
  .widget-item-on-grid {
    margin-top: 10px; /* RESPONSIVE */
    border-radius: 15%; /* RESPONSIVE */
    width: 80px !important; /* RESPONSIVE */
    height: 80px !important; /* RESPONSIVE */
  }

  /* =========== FOLDER STUFF ============  */
  .folder-item-container {
    border-radius: 15px; /* RESPONSIVE */
    height: 80px; /* RESPONSIVE */
    width: 80px; /* RESPONSIVE */
  }

  .folder-item-content {
    width: 80px; /* RESPONSIVE */
    height: 80px; /* RESPONSIVE */
  }

  .folder-small-item {
    width: 18px; /* RESPONSIVE */
    height: 18px; /* RESPONSIVE */
    border-radius: 6px; /* RESPONSIVE */
  }

  .empty-folder-icon {
    width: 30px; /* RESPONSIVE */
    height: 30px; /* RESPONSIVE */
  }

  .folder-item-title {
    font-size: 1em; /* RESPONSIVE */
  }

  .folder-item-container.empty .folder-item-title {
    margin-top: 0px; /* Space for empty folder */
  }

  .folder-item-container.not-empty .folder-item-title {
    margin-top: 30px; /* Less space for non-empty folder */
  }
  
  
  /* WEATHER DAILY ICON CSS */
  .weather-city {
    font-size: 0.8em !important;
    margin-bottom: -5px !important;
  }

  .weather-daily-icon {
    width: 45px !important;
    height: 45px !important;
  }
  .weather-daily-temp {
    bottom: 10px;
    font-weight: 800;
    margin-top: 0px !important;
    font-size: 1.2em !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .navbutton {
    font-weight: 500; /* RESPONSIVE */
    border-radius: 10px; /* RESPONSIVE */
    font-size: 1em; /* RESPONSIVE */
  }

  .main-grid-container {
    grid-template-columns: repeat(5, 10%); /* RESPONSIVE */
    grid-template-rows: repeat(9, 10%); /* RESPONSIVE */
    gap: 25%; /* RESPONSIVE */
    row-gap: 5%; /* RESPONSIVE */
    left: 35%; /* RESPONSIVE */
  }

  .common-grid-frame {
    bottom: 60px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
    width: 78%; /* RESPONSIVE */
  }

  .common-grid-container {
    grid-template-columns: repeat(8, 10%); /* RESPONSIVE */
    grid-template-rows: repeat(1, 5%); /* RESPONSIVE */
    gap: 2%; /* RESPONSIVE */
    row-gap: 0; /* RESPONSIVE */
    padding: 15px 15px 0px 10px; /* RESPONSIVE */
    height: 118px; /* RESPONSIVE */
    left: 200px !important; /* RESPONSIVE */
    width: 100%;
  }

  .grid-item {
    height: 80px; /* RESPONSIVE */
    width: 80px; /* RESPONSIVE */
    border-radius: 10px; /* RESPONSIVE */
  }

  .button-item {
    height: 80px; /* RESPONSIVE */
    width: 80px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
  }

  .button-image {
    top: 7%; /* RESPONSIVE */
    left: 0;
    width: 70px !important; /* RESPONSIVE */
    height: 70px !important; /* RESPONSIVE */
    border-radius: 13px; /* RESPONSIVE */

    margin: auto;
  }
  .button-title {
    top: 80px; /* RESPONSIVE */
    font-size: 1em !important; /* RESPONSIVE */
  }

  .folder-item {
    height: 80px; /* RESPONSIVE */
    width: 80px; /* RESPONSIVE */
  }

  /* ========== WIDGET CSS ==============*/
  .widget-item-on-grid {
    margin-top: 10px; /* RESPONSIVE */
    border-radius: 15%; /* RESPONSIVE */
    width: 80px !important; /* RESPONSIVE */
    height: 80px !important; /* RESPONSIVE */
  }

  /* =========== FOLDER STUFF ============  */
  .folder-item-container {
    height: 80px; /* RESPONSIVE */
    width: 80px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
  }

  .folder-item-content {
    width: 80px; /* RESPONSIVE */
    height: 80px; /* RESPONSIVE */
  }

  .folder-small-item {
    width: 18px; /* RESPONSIVE */
    height: 18px; /* RESPONSIVE */
    border-radius: 6px; /* RESPONSIVE */
  }

  .empty-folder-icon {
    width: 30px; /* RESPONSIVE */
    height: 30px; /* RESPONSIVE */
  }

  .folder-item-title {
    font-size: 1em; /* RESPONSIVE */
  }

  .folder-item-container.empty .folder-item-title {
    margin-top: 0px; /* Space for empty folder */
  }

  .folder-item-container.not-empty .folder-item-title {
    margin-top: 30px; /* Less space for non-empty folder */
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .navbutton {
    font-weight: 500; /* RESPONSIVE */
    border-radius: 10px; /* RESPONSIVE */
    font-size: 1em; /* RESPONSIVE */
  }

  .main-grid-container {
    grid-template-columns: repeat(9, 90px); /* RESPONSIVE */
    grid-template-rows: repeat(5, 90px); /* RESPONSIVE */
    gap: 25px; /* RESPONSIVE */
    row-gap: 60px !important; /* RESPONSIVE */
    top: 60px;
    left: 50%; /* RESPONSIVE */
  }

  .common-grid-frame {
    border-radius: 15px; /* RESPONSIVE */
    margin: auto;
    bottom: 40px;
    width: 970px;
  }
  .common-grid-container {
    display: flex;
    justify-content: center;
    grid-template-columns: repeat(8, 90px); /* RESPONSIVE */
    grid-template-rows: repeat(1, 90px); /* RESPONSIVE */
    gap: 25px; /* RESPONSIVE */

    padding: 15px 10px 0px 10px; /* RESPONSIVE */
    height: 140px; /* RESPONSIVE */
    left: 30%; /* RESPONSIVE */
    transform: translate(-20%); /* RESPONSIVE */
  }

  .grid-item {
    height: 90px; /* RESPONSIVE */
    width: 90px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
  }

  .button-item {
    height: 90px; /* RESPONSIVE */
    width: 90px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
  }

  .button-image {
    top: 5%; /* RESPONSIVE */
    left: 0;
    width: 80px !important; /* RESPONSIVE */
    height: 80px !important; /* RESPONSIVE */
    border-radius: 13px; /* RESPONSIVE */

    margin: auto;
  }
  .button-title {
    top: 90px; /* RESPONSIVE */
    font-size: 1.2em !important; /* RESPONSIVE */
  }
  .widget-item-title {
    font-size: 1.2em !important; /* RESPONSIVE */
  }
  .folder-item {
    height: 90px; /* RESPONSIVE */
    width: 90px; /* RESPONSIVE */
  }

  /* ========== WIDGET CSS ==============*/
  .widget-item-on-grid {
    margin-top: 22px; /* RESPONSIVE */
    border-radius: 15%; /* RESPONSIVE */
    width: 90px !important; /* RESPONSIVE */
    height: 90px !important; /* RESPONSIVE */
  }

  /* =========== FOLDER STUFF ============  */
  .folder-item-container {
    height: 90px; /* RESPONSIVE */
    width: 90px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
  }

  .folder-item-content {
    width: 90px; /* RESPONSIVE */
    height: 90px; /* RESPONSIVE */
  }

  .folder-small-item {
    width: 25px; /* RESPONSIVE */
    height: 25px; /* RESPONSIVE */
    border-radius: 5px; /* RESPONSIVE */
  }

  .empty-folder-icon {
    width: 30px; /* RESPONSIVE */
    height: 30px; /* RESPONSIVE */
  }

  .folder-item-title {
    font-size: 1.2em; /* RESPONSIVE */
  }

  .folder-item-container.empty .folder-item-title {
    margin-top: 0px; /* Space for empty folder */
  }

  .folder-item-container.not-empty .folder-item-title {
    margin-top: 30px; /* Less space for non-empty folder */
  }
}

@media (min-width: 1600px) {
  .navbutton {
    font-weight: 500; /* RESPONSIVE */
    border-radius: 10px; /* RESPONSIVE */
    font-size: 1em; /* RESPONSIVE */
  }

  .main-grid-container {
    grid-template-columns: repeat(9, 100px); /* RESPONSIVE */
    grid-template-rows: repeat(5, 100px); /* RESPONSIVE */
    gap: 20px; /* RESPONSIVE */
    row-gap: 30px !important; /* RESPONSIVE */
    /*  left: 35%; RESPONSIVE */
  }

  .common-grid-frame {
    bottom: 50px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
    width: 55%; /* RESPONSIVE */
  }

  .common-grid-container {
    grid-template-columns: repeat(8, 100px); /* RESPONSIVE */
    grid-template-rows: repeat(1, 100px); /* RESPONSIVE */
    gap: 20px; /* RESPONSIVE */
    row-gap: 0; /* RESPONSIVE */
    padding: 10px; /* RESPONSIVE */
    height: 118px; /* RESPONSIVE */
    left: 200px !important; /* RESPONSIVE */
    width: 100%;
  }

  .grid-item {
    height: 100px; /* RESPONSIVE */
    width: 100px; /* RESPONSIVE */
    border-radius: 10px; /* RESPONSIVE */
  }

  .button-item {
    height: 100px; /* RESPONSIVE */
    width: 100px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
  }

  .button-image {
    top: 5%; /* RESPONSIVE */
    left: 0%;
    width: 90px !important; /* RESPONSIVE */
    height: 90px !important; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */

    /* margin: auto; */
  }
  .button-title {
    top: 100px; /* RESPONSIVE */
    font-size: 1em !important; /* RESPONSIVE */
  }
  .widget-item-title {
    font-size: 1em !important; /* RESPONSIVE */
  }
  .folder-item {
    height: 100px; /* RESPONSIVE */
    width: 100px; /* RESPONSIVE */
  }

  /* ========== WIDGET CSS ==============*/
  .widget-item-on-grid {
    margin-top: 20px; /* RESPONSIVE */
    border-radius: 15%; /* RESPONSIVE */
    width: 100px !important; /* RESPONSIVE */
    height: 100px !important; /* RESPONSIVE */
  }

  /* =========== FOLDER STUFF ============  */
  .folder-item-container {
    height: 100px; /* RESPONSIVE */
    width: 100px; /* RESPONSIVE */
    border-radius: 15px; /* RESPONSIVE */
  }

  .folder-item-content {
    width: 100px; /* RESPONSIVE */
    height: 100px; /* RESPONSIVE */
  }

  .folder-small-item {
    width: 25px; /* RESPONSIVE */
    height: 25px; /* RESPONSIVE */
    border-radius: 6px; /* RESPONSIVE */
  }

  .empty-folder-icon {
    width: 30px; /* RESPONSIVE */
    height: 30px; /* RESPONSIVE */
  }

  .folder-item-title {
    font-size: 1em; /* RESPONSIVE */
    margin-top: 0px !important;
  }

  .folder-item-container.empty .folder-item-title {
    margin-top: 20px; /* Space for empty folder */
  }

  .folder-item-container.not-empty .folder-item-title {
    margin-top: 10px; /* Less space for non-empty folder */
  }
}
