.home-page {
  padding: 20px;
  background: linear-gradient(to right, #f0f2f5, #ffffff);
}

.home-header {
  text-align: center;
  margin-bottom: 20px;
}

.home-header h1 {
  font-size: 2.5em;
  color: #333;
}

.home-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */
}

.section-card {
  width: 30%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px; /* Add margin to separate sections */
  transition: transform 0.3s ease;
}

.section-card:hover {
  transform: translateY(-10px);
}

.welcome-message h2,
.navigation-links h3,
.recent-activity h3,
.documentation h2 {
  margin-bottom: 10px;
  color: #0073e6;
}

.navigation-links ul {
  list-style-type: none;
  padding: 0;
}

.navigation-links li {
  margin-bottom: 10px;
}

.navigation-links a {
  text-decoration: none;
  color: #0073e6;
  display: flex;
  align-items: center;
}

.navigation-links a:hover {
  text-decoration: underline;
}

.navigation-links a::before {
  content: "•";
  color: #0073e6;
  margin-right: 8px;
}

.profile-pic {
  border-radius: 50%;
  margin-top: 10px;
}
