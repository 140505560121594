.add-item-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  width: 200px;
}
.delete-button {
  width: 60px;
  font-size: 14px;
}
