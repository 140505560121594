.background-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.attribution {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.55);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 10001; /* Ensure it's above other elements */
}

.attribution button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 10002; /* Ensure the button is above other elements */
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10003; /* Ensure the dialog is above other elements */
}

.dialog button {
  margin: 5px;
  padding: 10px 15px;
  cursor: pointer;
  z-index: 10004; /* Ensure the button inside the dialog is above other elements */
}
