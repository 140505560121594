.main-grid-container {
  display: grid;
  grid-template-columns: repeat(9, 40px); /* RESPONSIVE */
  grid-template-rows: repeat(5, 32px); /* RESPONSIVE */
  gap: 20px; /* RESPONSIVE */
  row-gap: 18px; /* RESPONSIVE */
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);

  z-index: 1;
  /* border: black 1px solid; */
}

.common-grid-frame {
  position: fixed;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.08);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);

  bottom: 20px; /* RESPONSIVE */
  border-radius: 15px; /* RESPONSIVE */
  width: 60%; /* RESPONSIVE */
  /* border: #00e700 4px solid; */
}
.folder-grid.is-over {
  /* opacity: 0.95;  */
  /* transition: opacity 0.3s ease-in;  */
}
.common-grid-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 30px); /* RESPONSIVE */
  grid-template-rows: repeat(1, 30px); /* RESPONSIVE */
  gap: 20%; /* RESPONSIVE */
  row-gap: 20%; /* RESPONSIVE */
  padding: 10px 15px 0px 0px; /* RESPONSIVE */
  height: 110px; /* RESPONSIVE */
  left: 0%; /* RESPONSIVE */
  transform: translate(-20%); /* RESPONSIVE */
}

/* GRID ITEMS =================================== */
.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px; /* RESPONSIVE */
  width: 30px; /* RESPONSIVE */
  border-radius: 5px; /* RESPONSIVE */
  user-select: none;
  position: relative;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  z-index: 1;
  /* background-color: orange; */
}

.grid-item.folder-item {
  z-index: 100;
}

.grid-item.placeholder.dragging {
  border: 1px dashed orange;
  opacity: 1 !important;
}

ADDED FOR DRAGGING A LINK OVER A GRID ITEM
.grid-placeholder.drag-over {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.8);
  transition: box-shadow 0.3s ease;
}

.drag-over {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.8);
  transition: box-shadow 0.3s ease;
}

.grid-item:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.8);
  /* z-index: 200; */
}

.button-item {
  position: relative;
  display: inline-block !important;

  height: 30px; /* RESPONSIVE */
  width: 30px; /* RESPONSIVE */
  border-radius: 5px; /* RESPONSIVE */

  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  transform: none;
}

.button-image {
  position: relative;
  top: 8%; /* RESPONSIVE */
  left: 8%; /* RESPONSIVE */
  padding: 0;
  margin: 0;
  width: 25px !important; /* RESPONSIVE */
  height: 25px !important; /* RESPONSIVE */
  border-radius: 5px; /* RESPONSIVE */
  border: none;
  object-fit: cover;
}

.folder-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30px; /* RESPONSIVE */
  width: 30px; /* RESPONSIVE */
}

.delete-button {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px !important;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.delete-button:hover {
  transform: scale(1.1);
}

.delete-button svg {
  color: #ff4444;
  font-size: 20px;
}

.delete-button:hover {
  transform: scale(1.2);
}

.dragging {
  pointer-events: none !important;
  transform-origin: 0 0 !important;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  /* z-index: 9999; */
  pointer-events: auto !important;
}

/* CSS FOR THE GRID TO SHOW AND HIDE AS ITEMS ARE DRAGGED */
.placeholder {
  /* background-color: red !important; */
  /* opacity: 0; */
}
.navbutton {
  font-weight: 400; /* RESPONSIVE */
  border-radius: 10px; /* RESPONSIVE */
  font-size: 0.6em; /* RESPONSIVE */
}
/* ==== ENTIRE G R I D   I T E M S WHEN DRAGGING AN ITEM === */

.button-title {
  position: absolute;
  top: 30px; /* RESPONSIVE */
  font-size: 0.6em !important; /* RESPONSIVE */

  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
  white-space: nowrap;
}

/* ============================================ */
/* THE NEXT AND BACK BUTTONS TO CHANGE GRIDS */
.move-prev-grid {
  left: 0;
}
.move-next-grid {
  right: 0;
}
.move-prev-grid,
.move-next-grid {
  position: absolute;
  top: 50%;
  height: 100px;
  width: 80px;
  /* transform: translateY(-50%); */
  z-index: 1000;
  border: none;
  padding: 10px;
  cursor: pointer;
  background-color: transparent !important;
  /* border: #eed150 4px solid; */
}

/* ============================================== */
/* THESE ARE THE TWO BUTTONS THAT CHANGE GRIDS WHEN AN ITEM IS DRAGGED OVER THEM */
.prev-grid-button,
.next-grid-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  bottom: 0px;
  z-index: 100;
  border: none;
  padding: 10px;
  cursor: pointer;
  height: 50vh;
  width: 50px;
  border-radius: 5px;
  /* border: green 4px solid; */
}

.prev-grid-button {
  left: 0;
}

.next-grid-button {
  right: 0;
}

/* ============= GRID NAVIGATION =========== R1 */
.grid-navigation-container {
  position: absolute;
  height: 40px;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: 0px;
  align-items: center;
  width: 100%;
}

.grid-navigation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
}

.grid-navigation-buttons button.active {
  color: black;
  font-size: 1em;
  background-image: linear-gradient(to bottom right, #007a00, #00e700);
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}

.grid-navigation-buttons button:hover {
  background-image: linear-gradient(to bottom right, #007a00, #00e700);
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}

/* ========== WIDGET CSS ==============*/
.widget-item-on-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  /* border: 1px solid #f1f1f1; */
  text-align: center;
  margin-top: 15px; /* RESPONSIVE */
  border-radius: 15%; /* RESPONSIVE */
  width: 30px !important; /* RESPONSIVE */
  height: 30px !important; /* RESPONSIVE */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  /* user-select: none; */

  /* width: 100%;
  height: auto; */
}

.widget-item-title {
  position: relative;
  top: 0px;
  font-size: 0.6em; /* RESPONSIVE */
  color: white;
  width: 100%;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
  white-space: nowrap;
}

/* ============ NAVIGATION/USER BUTTONS =============*/
.next-button,
.previous-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: none;
  /* border: #007a00 1px solid; */
}

.next-nav-button img,
.next-nav-button svg,
.previous-button img,
.previous-button svg {
  width: 60px;
  height: 60px;
}

/* ============= MISCELANEOUS CSS ============= */
.screen-size-display {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 20px;
  /* width: 80px; */
  background-color: rgba(0, 0, 0, 0.47);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1000;
  font-size: 14px;
}

/* =========== FOLDER STUFF ============  */
.folder-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eed150;
  border-radius: 6px; /* RESPONSIVE */
  height: 30px; /* RESPONSIVE */
  width: 30px; /* RESPONSIVE */
}

.folder-item-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.folder-small-item {
  width: 6px; /* RESPONSIVE */
  height: 6px; /* RESPONSIVE */
  margin: 1px;
  border-radius: 1px; /* RESPONSIVE */
}

.empty-folder-icon {
  width: 30px; /* RESPONSIVE */
  height: 30px; /* RESPONSIVE */
  background-color: transparent !important;
  display: none;
}

.folder-item-title {
  font-size: 0.6em; /* RESPONSIVE */
  color: white;
  text-align: center;
  white-space: nowrap;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.folder-item-container.empty .folder-item-title {
  margin-bottom: -25px; /* Space for empty folder */
}

.folder-item-container.not-empty .folder-item-title {
  margin-bottom: -25px; /* Less space for non-empty folder */
}

.dragging-item-overlay {
  transform: none !important;
  pointer-events: auto;
  cursor: grabbing;
  opacity: 1;
  /* z-index: 999; */
}

.drag-overlay {
  position: fixed;
  z-index: 999;
  pointer-events: none;
  transform-origin: 0 0;
  backface-visibility: hidden;
}
