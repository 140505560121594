.text-badge-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .text-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;    user-select: none;
    text-align: center;
    padding: 5px 10px;
    margin: 15px 5px;
  }
  