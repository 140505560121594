/* ManageButtons.css */

.flexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.flexRow h3 {
  margin: 0;
  font-size: 1.5em;
  color: #333;
}

.handle-add-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 150px;
}

.handle-add-button:hover {
  background-color: #0056b3;
}

.admin-edit-widget-table {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

/* Error message styling */
.error-message {
  color: #dc3545;
  padding: 10px;
  margin: 10px 0;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

/* Loading message styling */
.loading-message {
  font-size: 1.2em;
  color: #555;
  margin-top: 20px;
}

.built-in-row {
  background-color: #f5f5f5;
}

.built-in-row input {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.btn-delete-btn {
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 3px;
  width: 70px;
}
