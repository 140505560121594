.table-container-abc {
  width: 100%;
  overflow-x: auto;
  /* border: 2px solid red; */
}

.table-wrapper-abc {
  width: 100%;
  overflow-x: auto;
  background-color: red;
}

.button-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
}

.button-table th,
.button-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.button-table th {
  background-color: #f4f4f4;
}

.flexRow-abc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.button-table .btn-save {
  margin: 2px;
}

.button-table .btn-edit {
  margin: 2px;
}

.button-table .btn-update {
  margin: 2px;
}

.button-table .btn-add {
  margin: 2px;
}

.button-table .btn-delete {
  margin: 2px;
}

.input-id {
  white-space: nowrap; /* Prevent the text from wrapping to the next line */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Show ellipsis (...) for the hidden text */
  max-width: 50px;
}
/*
.button-table .input-title {
  max-width: 150px;
}

.button-table .input-gridX {
  max-width: 50px;
}

.button-table .input-gridY {
  max-width: 50px;
}

.button-table .input-main_id {
  max-width: 50px;
}

.button-table .input-folder_id {
  max-width: 50px;
}

.button-table .input-common_id {
  max-width: 50px;
}

.button-table .input-url {
  max-width: 200px;
}

.button-table .input-newTab {
  max-width: 50px;
}

.button-table .select-iconOption {
  max-width: 100px;
}

.button-table .input-backgroundColor {
  max-width: 100px;
}

.button-table .input-visible {
  max-width: 50px;
}

.button-table .input-state {
  max-width: 50px;
} */
.button-2 {
  padding: 5px 10px;
  margin: 5px;
  height: 26px;
}
