.settings-page {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #f0f0f0;
}
/* .settings-page table {
  height: 100%;
} */

/* TABLE */
p.id {
  font-size: 12px;
  text-align: center;
  min-width: 100%;
  border-collapse: collapse;
  margin: 0;
  height: 20px;
}
hr {
  margin: 10px, 0, 0, 0;
  padding: 0;
  border: 0;
  border-top: 1px solid black;
}

table {
  border-collapse: collapse;
  margin: 0 !important;
  padding: 0;
}
td,
th {
  border: 1px solid black;
  padding: 0;
  margin: 0;
}
th {
  background-color: lightgray;
  font-size: 10px;
}
input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  text-align: center;
  font-size: 12px;
  padding: 4px;
  margin: 0;
  border: none;
}
.icon-size-button {
  /* width: 20px; */
  height: 30px;
}

.activeTab {
  font-size: 2em;
  color: white;
}

.settings-page-fade {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
