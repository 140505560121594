.dropdown {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  z-index: 1500;
  display: flex;
  gap: 10px;
  align-items: center;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  width: 40px;
  height: 40px;
  z-index: 1501;
}

.done-button {
  background: #ff4d4f;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1501;
}

.dropdown-content {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 300px;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  padding: 60px 12px 20px;
  overflow-y: auto;
  z-index: 1500;
}

.dropdown:hover .dropdown-content {
  transform: translateX(0);
}

.menu-section {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 4px;
  position: relative;
  margin: 10px 0;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.menu-section h3 {
  margin: 0;
  color: #666;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 12px;
  border-bottom: 1px solid #e0e0e0;
  letter-spacing: 0.5px;
  background-color: #f5f5f5;
  text-align: left;
}

.menu-section:first-child h3 {
  border-top: none;
}

/* Button management section specific style */
.menu-section.button-management {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.menu-item,
.menu-item a,
.menu-item button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  width: 100%;
  border: none;
  background: none !important;
  color: #333;
  text-decoration: none;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.menu-item:hover,
.menu-item a:hover,
.menu-item button:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.menu-item svg {
  width: 16px;
  color: #666;
}

.menu-item span {
  line-height: 1;
  margin-left: 8px;
}

/* Special states */
.menu-item.delete svg {
  color: #ff4d4f;
}

/* Remove all gradient backgrounds */
.menu-item.admin,
.menu-item.report,
.premium-item {
  background: none !important;
  color: #333;
  font-weight: normal;
}

.menu-item.admin:hover,
.menu-item.report:hover,
.premium-item:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
  transform: none;
}

.admin-section {
  background-color: transparent;
}

.report-issue {
  color: #666;
}

.update-section {
  background-color: #fff8e1;
  padding: 2px;
  display: flex;
  gap: 2px;
}

.premium-section {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 8px 0;
  margin: 4px 0;
}
