.widget-container {
  position: absolute;
  border: none;
  background: transparent;
  z-index: 1000; /* Ensure the widget is on top */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Add these to ensure no margin or padding issues */
  margin: 0;
  padding: 0;
}

.widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ccc; /* Ensure the header is visible */
  padding: 5px;
  cursor: grab;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  height: 30px;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
  user-select: none; /* Prevents text selection */
}

.widget-container:hover .widget-header,
.widget-container.dragging .widget-header {
  opacity: 1;
  pointer-events: all;
}

.widget-header .title {
  flex-grow: 1;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #ccc;
}

.widget-header .drag-handle {
  cursor: grab;
  margin-right: 5px;
  background: #ccc;
}

.widget-header .close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 2px 5px;
  font-size: 14px;
}

.widget-content {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent; /* Ensure the content background is transparent */
}
