/* General Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

h2 {
  text-align: center;
  margin-top: 20px;
  color: #333;
}

h3 {
  margin-top: 30px;
  text-align: center;
  color: #444;
}

/* Container Styles */
.item-table-container {
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 20px;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f8f8f8;
  color: #333;
}

td {
  background-color: #fff;
}

/* Input and Button Styles */
input[type="text"],
input[type="number"] {
  width: 90%;
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="checkbox"] {
  transform: scale(1.2);
}

button {
  padding: 8px 10px;
  margin: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button.btn-delete {
  background-color: #dc3545;
}

button.btn-delete:hover {
  background-color: #c82333;
}

/* Add Item Section */
.add-item-section {
  text-align: center;
  margin-top: 30px;
}

.add-item-section button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ffca28;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
}

.add-item-section button:hover {
  background-color: #ffc107;
}

/* Save to File Button */
.save-to-file-button {
  display: block;
  margin: 30px auto;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #28a745;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  width: 200px;
}

.save-to-file-button:hover {
  background-color: #218838;
}

.expiremental {
  background-color: rgb(255, 244, 230);
  width: 100%;
  padding: 10px;
}

.button-1 {
  padding: 5px 10px;
  margin: 5px;
}
