/* GridNavigation.css */

.navbutton {
  padding: 4px 8px;
  border: none;
  color: white;
  font-family: Montserrat, sans-serif;
  cursor: pointer;
  outline: none;
  text-shadow: 0 0 1px #000000, 0 0 1px #000000;

  font-weight: 600; /* RESPONSIVE */
  border-radius: 10px; /* RESPONSIVE */
  font-size: 0.8em; /* RESPONSIVE */
  margin-right: 5px; /* RESPONSIVE */
}

.button.active {
  color: black;
  background-image: linear-gradient(to bottom right, #007a00, #00e700);
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}

.button.inactive {
  background-image: linear-gradient(to bottom right, #00f800, #006d00);
}
.edit-input {
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  padding: 4px 8px;
  font-family: Montserrat, sans-serif;
  font-size: 1.2em;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  max-width: 100px;
}
