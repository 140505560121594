.profile-page {
  padding: 20px;
}

.profile-edit,
.profile-view {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profile-edit label,
.profile-view p {
  margin: 10px 0;
}

button {
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
}
